/* eslint-disable no-shadow */
import { Grid } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import { isValid } from 'clients';
import UploadFileClient from 'clients/UploadFileClient';
import { FILE_TYPE } from 'constants/Enums';
// @ts-ignore
import ImageBlobReduce from 'image-blob-reduce';
// @ts-ignore
import RUG, { DropArea } from 'lib/react-upload-gallery';
import { useTranslation } from 'next-i18next';
import Pica from 'pica';
import { useState } from 'react';
import { ImageFallback } from 'utils';
import { blobToBase64 } from 'utils/ImageUtils';
import NotifyUtils from 'utils/NotifyUtils';
import { v4 as uuidv4 } from 'uuid';

// 3.75mb
const LIMIT_SIZE = 3750000;

const UploadImages = (props) => {
  const { t: translateAlertAction } = useTranslation('alert-action');
  const { t: translatePopupAction } = useTranslation('popup-action');
  const { onChange, images = [], limit = 6, isAuth = true, setLoading, isOnlyOneImage = false } = props;

  const functionUpload = isAuth ? UploadFileClient.upload : UploadFileClient.uploadFeedbackMock;

  const [imgState, setImgState] = useState(images || []);
  const [imageUrls, setImageUrls] = useState([]);

  const handleDelete = (image) => {
    let arr = imageUrls;
    arr = arr.filter((item) => item !== image.source);
    setImageUrls(arr);
    onChange(arr);
  };

  const customRequest = async ({ uid, file, action, onSuccess, onError }) => {
    if (setLoading) {
      setLoading();
    }
    const image = file;
    const { type } = file;
    const copyImageUrls = imageUrls;
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = async () => {
      let base64 = reader.result;
      let blob = new Blob([base64]);
      let limitSize = blob.size / 1024 / 1024;

      if ((type === FILE_TYPE.PNG || type === FILE_TYPE.JPEG) && limitSize > 2) {
        const pica = Pica({ features: ['js', 'wasm', 'cib'] });
        const reduce = new ImageBlobReduce({ pica });
        blob = await reduce.toBlob(file, { max: 2000 });
        base64 = await blobToBase64(blob);

        const buffer = Buffer.from(base64.substring(base64.indexOf(',') + 1)); // calculate size of base64
        limitSize = buffer.length / 1e6;
      }
      // size of base 64 = src * (4/3)
      if (limitSize > 5) {
        NotifyUtils.error(translateAlertAction('message_big_size_img'));

        onError(uid, {
          action,
          response: base64,
        });
      } else {
        // declare function upload above -> gọi vào mock api /upload ( auth ) hoặc /upload/feedback ( no auth )
        functionUpload({ body: { file: base64, type } })
          .then((result) => {
            if (!isValid(result)) {
              NotifyUtils.error(result?.message || translateAlertAction('file_upload_failed'));
              onError(uid, {
                action,
                status: result.message,
                response: reader.result,
              });
            } else {
              onSuccess(uid, result);
              copyImageUrls.push(result.data[0]);
              setImageUrls(copyImageUrls);
              onChange(copyImageUrls);
            }
          })
          .catch((error) => {
            onError(uid, {
              action,
              status: error.request,
              response: error.response,
            });
            onChange(copyImageUrls);
          });
      }
    };

    return {
      abort() {},
    };
  };

  if (isOnlyOneImage && imageUrls?.length > 0) {
    return (
      <Grid direction="row" key={uuidv4()} container>
        {imageUrls?.map((image) => (
          <Grid item key={uuidv4()} style={{ padding: 5 }}>
            <ImageFallback src={image} width={300} height={150} />
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <RUG
      rules={{
        limit,
        size: LIMIT_SIZE,
      }}
      accept={['jpg', 'jpeg', 'png']}
      source={(response) => response.data[0]}
      onDeleted={(image) => handleDelete(image)}
      initialState={imgState}
      onWarning={(type, rules) => {
        switch (type) {
          case 'accept':
            NotifyUtils.error(`${translateAlertAction('allow_format')} ${rules.accept.join(', ')}`);
            break;
          case 'limit':
            NotifyUtils.error(`${translateAlertAction('allow_max_quantity').replace('{quantity}', rules.limit)}`);
            break;
          case 'size':
            NotifyUtils.error(`${translateAlertAction('allow_max_size').replace('{size}', 5)}`);
            break;
          default:
        }
      }}
      onChange={(imgs) => {
        const newImgs = imgs?.filter((img) => img?.file?.size < LIMIT_SIZE) || [];
        setImgState(newImgs);
      }}
      header={({ openDialogue }) => (
        <DropArea>
          {(isDrag) => (
            <div className={`rug-handle ${isDrag ? '__dragging' : ''}`} onClick={openDialogue} onKeyDown={openDialogue} aria-hidden="true">
              <CloudUpload className={`rug-handle-icon ${isDrag ? '__arrow' : ''}`} />
              <div className="rug-handle-info">
                <div className="rug-handle-drop-text">{translatePopupAction('popup-ticket.upload_images')}</div>
                <div className="rug-handle-limit-message">({translatePopupAction('popup-ticket.max_images')})</div>
              </div>
            </div>
          )}
        </DropArea>
      )}
      customRequest={customRequest}
    >
      {(images) => {
        if (isOnlyOneImage) return null;

        return (
          <Grid direction="row" key={uuidv4()} container>
            {images
              ?.filter((img) => img?.file?.size < LIMIT_SIZE)
              .map((image) => (
                <Grid item key={uuidv4()} style={{ padding: 5 }}>
                  <ImageFallback src={image.source} width={90} height={90} />
                </Grid>
              ))}
          </Grid>
        );
      }}
    </RUG>
  );
};

export default UploadImages;
